import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { EpisodeList } from "./List"

export default () => (
  <StaticQuery
    query={graphql`
      query EpisodeListQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "episode" } } }
          limit: 4
        ) {
          edges {
            node {
              excerpt(pruneLength: 140)
              id
              fields {
                slug
              }
              frontmatter {
                tags
                title
                embedSrc
                description
                advancedId
                date(formatString: "MMMM DD, YYYY")
                featuredImage {
                  img {
                    childImageSharp {
                      fluid(maxWidth: 512, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <EpisodeList data={data} count={count} />}
  />
)
