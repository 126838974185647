import React from "react"
import SEO from "../components/seo"
import { Link as GatsbyLink } from "gatsby"
import { Footer, FooterLinks } from "../components/Layout/Footer"
import Layout from "../components/Layout"
import SmallEpisodeList from "../components/Episode/SmallList"
import HostsDetails from "../components/Hosts/Details"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Link from "@material-ui/core/Link"
import Box from "@material-ui/core/Box"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container style={{ paddingBottom: "20vh", paddingTop: 16 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" gutterBottom>
            Episodes
          </Typography>
          <SmallEpisodeList />
          <Box my={4} textAlign="center">
            <Link component={GatsbyLink} to="/episodes">
              All Episodes →
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box position="sticky" top={0}>
            <Typography variant="h4" gutterBottom>
              Your Hosts
            </Typography>
            <HostsDetails />
          </Box>
        </Grid>
      </Grid>
    </Container>
    <Footer />
    <FooterLinks />
  </Layout>
)

export default IndexPage
